<template>
  <!-- <h1>志愿者档案</h1> -->
  <div id="colunteerInfo">
    <el-form :inline="true" :model="formInline" size="mini">
  <el-form-item label="审批人">
    <el-input v-model="formInline.user" placeholder="审批人"></el-input>
  </el-form-item>
  <el-form-item label="活动区域">
    <el-select v-model="formInline.region" placeholder="活动区域">
      <el-option label="区域一" value="shanghai"></el-option>
      <el-option label="区域二" value="beijing"></el-option>
    </el-select>
  </el-form-item>
  <el-form-item>
    <el-button type="primary" @click="onSubmit" >查询</el-button>
  </el-form-item>
</el-form>
<div class="addBtn">
  <el-button type="primary" @click="addInfoHandle" size="mini" >新增志愿者</el-button>

</div>

<el-table
    :data="tableData"
    border
    
   >
    <el-table-column
      fixed
      prop="date"
      label="序号"
     >
    </el-table-column>
    <el-table-column
      prop="name"
      label="姓名"
   >
    </el-table-column>
    <el-table-column
      prop="province"
      label="性别"
      >
    </el-table-column>
    <el-table-column
      prop="city"
      label="手机号"
      >
    </el-table-column>
    <el-table-column
      prop="address"
      label="身份证号"
      >
    </el-table-column>
    <el-table-column
      prop="zip"
      label="服务区域"
        >
    </el-table-column>
    <el-table-column
      prop="zip"
      label="服务时长"
   >
    </el-table-column>
     <el-table-column
      fixed="right"
      label="操作"
      width="100">
      <template slot-scope="scope">
        <el-button @click="handleClick(scope.row)" type="text" size="small">编辑</el-button>
        <el-button type="text" size="small" style="color:red">删除</el-button>
      </template>
    </el-table-column>
  </el-table>

<addInfo v-if="isdialogVisible"></addInfo>
  </div>
</template>

<script>
import addInfo from './component/addInfo'
export default {
  components:{
    addInfo
  },
  data(){
    return {
      formInline : {
        user:'',
        region:''
      },
      isdialogVisible:false,
      tableData:[{
          date: '2016-05-03',
          name: '王小虎',
          province: '上海',
          city: '普陀区',
          address: '上海市普陀区金沙江路 1518 弄',
          zip: 200333
        }, {
          date: '2016-05-02',
          name: '王小虎',
          province: '上海',
          city: '普陀区',
          address: '上海市普陀区金沙江路 1518 弄',
          zip: 200333
        }]
    }
  },
  methods:{
    onSubmit(){

    },
    addInfoHandle(){
      this.isdialogVisible = true
    },

  }
}
</script>

<style lang="scss" scoped>

#colunteerInfo{
  padding: 20px;
  width: 100%;
  height: 100%;
  overflow:auto ;
}
.addBtn{
  margin-bottom: 20px;
}
</style>