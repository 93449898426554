<template>
  <!-- <h1>新增志愿者信息</h1> -->
    <el-dialog
  title="新增志愿者信息"
  :visible.sync="dialogVisible"
  width="50%"
  :before-close="handleClose"
  >
  

</el-dialog>
</template>

<script>
export default {
  data(){
    return {
      dialogVisible:true
    }
  },
  created(){
    console.log(123);
  },
  methods:{
    handleClose(){
      this.$parent.isdialogVisible = false
    }
  }
}
</script>

<style>

</style>